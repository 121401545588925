import * as React from "react"

import { useClerk } from "@clerk/remix"
import { useRevalidator } from "@remix-run/react"

export function useLogOut() {
  const clerk = useClerk()
  const revalidator = useRevalidator()
  const [isLoggingOut, setIsLoggingOut] = React.useState(false)

  const logOut = async () => {
    setIsLoggingOut(true)

    await clerk.signOut()
    revalidator.revalidate()

    setIsLoggingOut(false)
  }

  return { logOut, isLoggingOut }
}
