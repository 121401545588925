import type { RemixLinkProps } from "@remix-run/react/dist/components"
import type { TapHeaderItem } from "../../lib/constants.ts"

import * as Icon from "@iyk/icons"
import * as UI from "@iyk/ui"
import * as React from "react"
import * as DropdownMenu from "../../lib/ui/dropdown-menu.tsx"

import { toSentenceCase } from "@iyk/string"
import { Link } from "@remix-run/react"
import { AnimatePresence, motion } from "framer-motion"
import { TAP_HEADER_DROPDOWN_ITEMS } from "../../lib/constants.ts"
import { useIykUser } from "../hooks/use-iyk-user.ts"
import { useLogOut } from "../hooks/use-log-out.ts"
import { useLang } from "../lang/use-lang.ts"

export const AccountDropdown = () => {
  const { iykUser } = useIykUser()
  const { logOut, isLoggingOut } = useLogOut()

  const handleSignOut = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    logOut()
  }

  if (!iykUser) return null

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className="text-gray-12 shadow-none p-1.5 flex items-center gap-0.5">
        <UI.Text size="xs">{iykUser.username}</UI.Text>
        <Icon.ChevronDown />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        align="end"
        className="min-w-60"
        onCloseAutoFocus={(e) => e.preventDefault()} // prevent focus on trigger when closing
      >
        {TAP_HEADER_DROPDOWN_ITEMS.map((item) => (
          <DropdownMenuItem
            key={item.term}
            item={item}
            isLoggingOut={isLoggingOut}
            handleSignOut={handleSignOut}
          />
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}

const DropdownMenuItem = ({
  item,
  isLoggingOut,
  handleSignOut,
}: {
  item: TapHeaderItem
  isLoggingOut: boolean
  handleSignOut: (e: React.MouseEvent<HTMLDivElement>) => void
}) => {
  const showLogoutSpinner = isLoggingOut && item.type === "logout"
  const commonProps = { className: "flex gap-2 items-center hover:cursor-pointer" }

  if (item.href) {
    const linkProps: RemixLinkProps = {
      ...commonProps,
      to: item.href,
    }

    return (
      <DropdownMenu.Item key={item.term} disabled={showLogoutSpinner} asChild>
        <Link {...linkProps}>
          <ItemContent item={item} showLogoutSpinner={showLogoutSpinner} />
        </Link>
      </DropdownMenu.Item>
    )
  }

  const itemProps = {
    ...commonProps,
    onClick: item.type === "logout" ? handleSignOut : undefined,
  }

  return (
    <DropdownMenu.Item key={item.term} disabled={showLogoutSpinner} {...itemProps}>
      <ItemContent item={item} showLogoutSpinner={showLogoutSpinner} />
    </DropdownMenu.Item>
  )
}

const ItemContent = ({
  item,
  showLogoutSpinner,
}: {
  item: TapHeaderItem
  showLogoutSpinner: boolean
}) => {
  const lang = useLang()

  return (
    <>
      <item.icon className="text-gray-11" />
      <UI.Text size="sm" className="pt-0.5">
        {toSentenceCase(lang.terms[item.term])}
      </UI.Text>
      <AnimatePresence>
        {showLogoutSpinner && (
          <motion.div
            className="ml-auto origin-center"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
          >
            <Icon.LoadingSpinner className="animate-spin w-3 h-3" />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
